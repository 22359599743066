<template>
    <div class="container my-3">
        <error-banner v-if="errors" :close="true" />
        <div class="riepilogo">
            <div class="form-header-riepilogo-riepilogo--box">
                <ul class="form-header--riepilogo-riepilogo--luogo lg">
                    <li class="mr-2 servizio">
                        <i class="fas fa-concierge-bell mt-1"></i>
                        <span v-html="manage.servizio.type"> </span>
                    </li>

                    <li class="mr-2 area" v-if="manage.area.name">
                        <i class="far fa-building mt-1"></i>
                        <span v-html="manage.area.name"></span>
                    </li>

                    <li class="indirizzo" v-if="manage.area.address">
                        <i class="fas fa-map-marker-alt mt-1"></i>
                        <span v-html="manage.area.address"></span>
                    </li>
                    <li
                        class="indirizzo"
                        v-if="manage.entry && !publicPosition"
                    >
                        <i class="fas fa-laptop-house mt-1"></i>
                        <span v-html="manage.risorsa"></span>
                    </li>
                </ul>
                <ul class="form-header--riepilogo-riepilogo--dati lg">
                    <li>
                        <i class="fas fa-hourglass-half"></i>
                        {{
                            secondsToString(
                                manage.entry.end_time - manage.entry.start_time,
                                this
                            )
                        }}
                    </li>
                    <li>
                        <i class="far fa-calendar-alt"></i>
                        {{
                            new Date(manage.entry.day_time).toLocaleDateString(
                                lang,
                                {
                                    weekday: "short",
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric"
                                }
                            )
                        }}
                    </li>
                    <li>
                        <i class="far fa-clock"></i>
                        {{
                            new Date(
                                new Date(
                                    manage.entry.start_time * 1000
                                ).toLocaleString("en-US", {
                                    timeZone: timezone
                                })
                            ).toLocaleTimeString(lang, {
                                hour: "2-digit",
                                minute: "2-digit"
                            })
                        }}
                        -
                        {{
                            new Date(
                                new Date(
                                    manage.entry.end_time * 1000
                                ).toLocaleString("en-US", {
                                    timeZone: timezone
                                })
                            ).toLocaleTimeString(lang, {
                                hour: "2-digit",
                                minute: "2-digit"
                            })
                        }}
                    </li>
                </ul>
            </div>
        </div>
        <div>
            <div v-if="form.datiUtente && form.datiUtente.length > 0">
                <div v-if="showErrors" class="alert alert-danger" role="alert">
                    {{ $t("form.field_error") }}
                </div>
                <h3 class="my-5 text-center">
                    {{ $t("manage.edit.dati_utente") }}
                </h3>

                <easy-input
                    v-for="input in form.datiUtente"
                    :key="input.id"
                    :disabled="input.primaria == 1 || input.identificativo == 1"
                    ref="input"
                    :input="input"
                    :showErrors="showErrors"
                    :touch="touch"
                />
            </div>
            <div v-if="form.datiServizio && form.datiServizio.length > 0">
                <h3 class="my-5 text-center">
                    {{ $t("manage.edit.dati_servizio") }}
                </h3>

                <easy-input
                    v-for="input in form.datiServizio"
                    :key="input.id"
                    ref="input"
                    :input="input"
                    :showErrors="showErrors"
                    :disabled="input.primaria == 1 || input.identificativo == 1"
                    :touch="touch"
                />
            </div>
            <div v-if="form.altriDati && form.altriDati.length > 0">
                <easy-input
                    v-for="input in form.altriDati"
                    :key="input.id"
                    ref="input"
                    :input="input"
                    :disabled="input.primaria == 1 || input.identificativo == 1"
                    :showErrors="showErrors"
                    :touch="touch"
                />
            </div>
        </div>
        <e-button @click="submit" class="my-3 btn btn-lg btn-primary w-100">
            {{ $t("buttons.conferma_modifiche") }}
        </e-button>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import EasyInput from "../../components/inputs/EasyInput";
import ErrorBanner from "../../components/ErrorBanner";
import { publicPosition } from "../../mixins/publicPosition";
/**
 * @vuese
 * @group Gestisci
 * Pagina di modifica della prenotazione
 */
export default {
    name: "Edit",
    mixins: [publicPosition],
    components: {
        EasyInput,
        ErrorBanner
    },
    data() {
        return {
            form: {
                datiUtente: null,
                datiServizio: null,
                altriDati: null
            },
            model: null,
            next: false,
            showErrors: false,
            touch: false
        };
    },
    beforeRouteLeave(_, __, next) {
        this.clearErrors();
        next();
    },
    computed: {
        ...mapState({
            cliente: state => state.Ente.cliente,
            servizio: state => state.Manage.manage.entry,
            formFields: state => state.Utils.form,
            manageFields: state => state.Manage.manage.public_fields,
            errors: state => state.Utils.errors,
            manage: state => state.Manage.manage
        }),
        timezone() {
            return process.env.VUE_APP_TIMEZONE;
        },
        to() {
            return "Calendario";
        }
    },
    mounted() {
        this.setForm().then(() => {
            for (const field in this.manageFields) {
                this.$refs.input.map(input => {
                    if (input.$v[field]) {
                        input.$v[field].$model = this.manageFields[field];
                    }
                });
            }
        });
    },
    methods: {
        /**
         * @vuese
         * MapMutations
         * Ritorna mutations dallos state
         * @mutations setMessage [imposta messaggi di successo]
         * @mutations setErrors  [imposta messaggi di errore]
         * @mutations clearErrors [elimina gli errori dallo state]
         */
        ...mapMutations({
            setMessage: "Utils/SET_MESSAGES",
            setErrors: "Utils/SET_ERRORS",
            clearErrors: "Utils/CLEAR_ERRORS"
        }),
        /**
         * @vuese
         * funzione asincrona
         * valida il form poi fa il submit all'api
         * in caso di successo setta un messaggio e naviga alla pagina precedente (Show)
         * in caso di errore setta showErrors a true
         */
        async submit() {
            this.setTouch().then(() =>
                this.validate()
                    .then(fields => {
                        const primaria =
                            this.$route.query.primaria ||
                            this.$route.query.chiave;
                        this.$http
                            .post(
                                `/api/entry/update/${this.$route.params.codice}?chiave=${primaria}`,
                                {
                                    public_fields: {
                                        ...Object.assign(...fields)
                                    }
                                }
                            )

                            .then(() => {
                                this.setMessage({
                                    name: "MODIFIED",
                                    message:
                                        "Prenotazione modificata correttamente"
                                });
                                this.$router.go(-1);
                            })
                            .catch(e => this.setErrors(e));
                    })

                    .catch(() => {
                        this.showErrors = true;
                    })
            );
        },
        /**
         * @vuese
         * Fa partire la validazione
         */
        setTouch() {
            return new Promise(resolve => {
                this.touch = true;
                resolve();
            });
        },
        /**
         * @vuese
         * carica i campi del form
         */
        setForm() {
            return new Promise(resolve => {
                this.form.datiUtente = this.formFields.filter(
                    e => e.posizione == 1
                );
                this.form.datiServizio = this.formFields.filter(
                    e => e.posizione == 2
                );
                this.formaltriDati = this.formFields.filter(
                    e => e.posizione != 1 && e.posizione != 2
                );
                resolve();
            });
        },
        /**
         * @vuese
         * valida i campi
         * ritorna una Primise
         * in caso di errore ritroena reject
         */
        validate() {
            return new Promise((resolve, reject) => {
                const isIncorrect = this.$refs.input.filter(e => e.$v.$error);
                if (isIncorrect.length > 0) {
                    return reject();
                }
                let fields = this.$refs.input.map(input => {
                    return this.formFields
                        .map(el => {
                            if (input.$v[el.codice]) {
                                return {
                                    [el.codice]: input.$v[el.codice].$model
                                };
                            }
                        })
                        .filter(function(el) {
                            return el != null;
                        })[0];
                });
                // this.setDatiUtente(Object.assign(...fields));

                return resolve(fields);
            });
        }
    }
};
</script>

<style scoped>
.form-header-riepilogo-riepilogo--box {
    border: 1px solid rgb(119, 119, 119);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #eee;
    /* padding-left: 20%; */
}

ul {
    padding: 0.3rem;
}

.form-header--riepilogo-riepilogo.lg {
    background-color: white;
    padding: 0.9rem 1.5rem;
    display: flex;
    flex-direction: column;
}

.form-header--riepilogo-riepilogo--luogo.lg li {
    margin-bottom: 10px;
    display: flex;
}

.form-header--riepilogo-riepilogo--luogo.lg li i {
    margin-right: 20px;
    font-size: 1.2rem;
}

.form-header--riepilogo-riepilogo--dati.lg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    font-size: 1rem;
    margin-top: 1rem auto;
    flex-wrap: wrap;
}

.form-header--riepilogo-riepilogo--dati.lg li {
    margin-right: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-header--riepilogo-riepilogo--dati.lg li i {
    margin-right: 10px;
}

.form-header--riepilogo-riepilogo--dati {
    display: flex;
    justify-content: space-around;
    font-size: 0.8rem;
}

.form-header--riepilogo {
    background: #eee;
    width: 100%;

    padding: 1rem 8rem;

    overflow: hidden;
    border-radius: 10px;
}

@media screen and (max-width: 600px) {
    .form-header--riepilogo-riepilogo.lg {
        width: 100%;
        padding: 0;
    }

    .form-header--riepilogo {
        padding: 0.8rem 0.3rem;
    }

    .form-header-riepilogo-riepilogo--box {
        padding-left: 1rem;
    }
}
</style>
